	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/05/2018 13:55
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-right-arrow:before { content: "\f100"; }
.flaticon-arrow-point-to-right:before { content: "\f101"; }
.flaticon-sun:before { content: "\f102"; }
.flaticon-check-mark:before { content: "\f103"; }
.flaticon-tick:before { content: "\f104"; }
.flaticon-hotel-1:before { content: "\f105"; }
.flaticon-checked-box:before { content: "\f106"; }
.flaticon-parking-1:before { content: "\f107"; }
.flaticon-pool:before { content: "\f108"; }
.flaticon-map-marker:before { content: "\f109"; }
.flaticon-signs-4:before { content: "\f10a"; }
.flaticon-worldwide:before { content: "\f10b"; }
.flaticon-signs-3:before { content: "\f10c"; }
.flaticon-placeholder-3:before { content: "\f10d"; }
.flaticon-placeholder-2:before { content: "\f10e"; }
.flaticon-musica-searcher:before { content: "\f10f"; }
.flaticon-magnifying-glass:before { content: "\f110"; }
.flaticon-search:before { content: "\f111"; }
.flaticon-404-error:before { content: "\f112"; }
.flaticon-airport:before { content: "\f113"; }
.flaticon-tag:before { content: "\f114"; }
.flaticon-support-2:before { content: "\f115"; }
.flaticon-climbing:before { content: "\f116"; }
.flaticon-time:before { content: "\f117"; }
.flaticon-lighthouse:before { content: "\f118"; }
.flaticon-sale:before { content: "\f119"; }
.flaticon-credit-card-7:before { content: "\f11a"; }
.flaticon-user-5:before { content: "\f11b"; }
.flaticon-room-service:before { content: "\f11c"; }
.flaticon-key-card:before { content: "\f11d"; }
.flaticon-coffee-cup:before { content: "\f11e"; }
.flaticon-hanger:before { content: "\f11f"; }
.flaticon-information:before { content: "\f120"; }
.flaticon-elevator:before { content: "\f121"; }
.flaticon-cutlery:before { content: "\f122"; }
.flaticon-reception:before { content: "\f123"; }
.flaticon-shower:before { content: "\f124"; }
.flaticon-bed-1:before { content: "\f125"; }
.flaticon-bell:before { content: "\f126"; }
.flaticon-bed:before { content: "\f127"; }
.flaticon-valet:before { content: "\f128"; }
.flaticon-parking:before { content: "\f129"; }
.flaticon-car-3:before { content: "\f12a"; }
.flaticon-restroom:before { content: "\f12b"; }
.flaticon-handicap:before { content: "\f12c"; }
.flaticon-trekking:before { content: "\f12d"; }
.flaticon-world-2:before { content: "\f12e"; }
.flaticon-money-1:before { content: "\f12f"; }
.flaticon-people-1:before { content: "\f130"; }
.flaticon-guide-1:before { content: "\f131"; }
.flaticon-guide-book:before { content: "\f132"; }
.flaticon-dove:before { content: "\f133"; }
.flaticon-tickets-1:before { content: "\f134"; }
.flaticon-share:before { content: "\f135"; }
.flaticon-paper-plane-1:before { content: "\f136"; }
.flaticon-internet-1:before { content: "\f137"; }
.flaticon-headphone:before { content: "\f138"; }
.flaticon-message:before { content: "\f139"; }
.flaticon-support-1:before { content: "\f13a"; }
.flaticon-pin-1:before { content: "\f13b"; }
.flaticon-world-1:before { content: "\f13c"; }
.flaticon-book:before { content: "\f13d"; }
.flaticon-contract:before { content: "\f13e"; }
.flaticon-buy:before { content: "\f13f"; }
.flaticon-percentage:before { content: "\f140"; }
.flaticon-sell:before { content: "\f141"; }
.flaticon-id-card:before { content: "\f142"; }
.flaticon-chat-3:before { content: "\f143"; }
.flaticon-businessman:before { content: "\f144"; }
.flaticon-money-bag:before { content: "\f145"; }
.flaticon-shield:before { content: "\f146"; }
.flaticon-password:before { content: "\f147"; }
.flaticon-exchange:before { content: "\f148"; }
.flaticon-people:before { content: "\f149"; }
.flaticon-qr-code:before { content: "\f14a"; }
.flaticon-tools:before { content: "\f14b"; }
.flaticon-eye-1:before { content: "\f14c"; }
.flaticon-commerce:before { content: "\f14d"; }
.flaticon-signs-2:before { content: "\f14e"; }
.flaticon-marketing:before { content: "\f14f"; }
.flaticon-social-media:before { content: "\f150"; }
.flaticon-target:before { content: "\f151"; }
.flaticon-leaf:before { content: "\f152"; }
.flaticon-question-1:before { content: "\f153"; }
.flaticon-attach:before { content: "\f154"; }
.flaticon-technology-2:before { content: "\f155"; }
.flaticon-diskette:before { content: "\f156"; }
.flaticon-clicker:before { content: "\f157"; }
.flaticon-hands:before { content: "\f158"; }
.flaticon-interface-4:before { content: "\f159"; }
.flaticon-technology-1:before { content: "\f15a"; }
.flaticon-telephone:before { content: "\f15b"; }
.flaticon-thunderbolt:before { content: "\f15c"; }
.flaticon-nature:before { content: "\f15d"; }
.flaticon-summer:before { content: "\f15e"; }
.flaticon-home:before { content: "\f15f"; }
.flaticon-idea:before { content: "\f160"; }
.flaticon-down-arrow:before { content: "\f161"; }
.flaticon-medal:before { content: "\f162"; }
.flaticon-followers:before { content: "\f163"; }
.flaticon-social-1:before { content: "\f164"; }
.flaticon-shapes-2:before { content: "\f165"; }
.flaticon-monitor:before { content: "\f166"; }
.flaticon-smile:before { content: "\f167"; }
.flaticon-key:before { content: "\f168"; }
.flaticon-credit-card-6:before { content: "\f169"; }
.flaticon-shapes-1:before { content: "\f16a"; }
.flaticon-loading:before { content: "\f16b"; }
.flaticon-instagram:before { content: "\f16c"; }
.flaticon-sad:before { content: "\f16d"; }
.flaticon-arrow:before { content: "\f16e"; }
.flaticon-social:before { content: "\f16f"; }
.flaticon-add-user:before { content: "\f170"; }
.flaticon-user-4:before { content: "\f171"; }
.flaticon-favorite:before { content: "\f172"; }
.flaticon-technology:before { content: "\f173"; }
.flaticon-folder:before { content: "\f174"; }
.flaticon-signs-1:before { content: "\f175"; }
.flaticon-expand:before { content: "\f176"; }
.flaticon-internet:before { content: "\f177"; }
.flaticon-interface-3:before { content: "\f178"; }
.flaticon-shopping:before { content: "\f179"; }
.flaticon-shapes:before { content: "\f17a"; }
.flaticon-tool:before { content: "\f17b"; }
.flaticon-signs:before { content: "\f17c"; }
.flaticon-eye:before { content: "\f17d"; }
.flaticon-photo:before { content: "\f17e"; }
.flaticon-pin:before { content: "\f17f"; }
.flaticon-refresh:before { content: "\f180"; }
.flaticon-filter:before { content: "\f181"; }
.flaticon-log-in:before { content: "\f182"; }
.flaticon-arrows-1:before { content: "\f183"; }
.flaticon-cogwheel:before { content: "\f184"; }
.flaticon-left-arrow:before { content: "\f185"; }
.flaticon-menu:before { content: "\f186"; }
.flaticon-music-1:before { content: "\f187"; }
.flaticon-unlock:before { content: "\f188"; }
.flaticon-email:before { content: "\f189"; }
.flaticon-communication:before { content: "\f18a"; }
.flaticon-music:before { content: "\f18b"; }
.flaticon-edit:before { content: "\f18c"; }
.flaticon-calendar-3:before { content: "\f18d"; }
.flaticon-wall-clock:before { content: "\f18e"; }
.flaticon-padlock:before { content: "\f18f"; }
.flaticon-tickets:before { content: "\f190"; }
.flaticon-guide:before { content: "\f191"; }
.flaticon-minibus-1:before { content: "\f192"; }
.flaticon-guests:before { content: "\f193"; }
.flaticon-error-2:before { content: "\f194"; }
.flaticon-plane:before { content: "\f195"; }
.flaticon-discount-1:before { content: "\f196"; }
.flaticon-support:before { content: "\f197"; }
.flaticon-placeholder-1:before { content: "\f198"; }
.flaticon-card:before { content: "\f199"; }
.flaticon-credit-card-5:before { content: "\f19a"; }
.flaticon-world:before { content: "\f19b"; }
.flaticon-money:before { content: "\f19c"; }
.flaticon-discount:before { content: "\f19d"; }
.flaticon-transport-3:before { content: "\f19e"; }
.flaticon-24-hours-2:before { content: "\f19f"; }
.flaticon-credit-card-4:before { content: "\f1a0"; }
.flaticon-24-hours-1:before { content: "\f1a1"; }
.flaticon-volunteer:before { content: "\f1a2"; }
.flaticon-study:before { content: "\f1a3"; }
.flaticon-error-1:before { content: "\f1a4"; }
.flaticon-credit-card-3:before { content: "\f1a5"; }
.flaticon-credit-card-2:before { content: "\f1a6"; }
.flaticon-dollar:before { content: "\f1a7"; }
.flaticon-history:before { content: "\f1a8"; }
.flaticon-like-2:before { content: "\f1a9"; }
.flaticon-invoice:before { content: "\f1aa"; }
.flaticon-room-key-1:before { content: "\f1ab"; }
.flaticon-transport-2:before { content: "\f1ac"; }
.flaticon-discount-voucher:before { content: "\f1ad"; }
.flaticon-transport-1:before { content: "\f1ae"; }
.flaticon-family:before { content: "\f1af"; }
.flaticon-garden:before { content: "\f1b0"; }
.flaticon-recommended:before { content: "\f1b1"; }
.flaticon-binoculars:before { content: "\f1b2"; }
.flaticon-credit-card-1:before { content: "\f1b3"; }
.flaticon-car-2:before { content: "\f1b4"; }
.flaticon-car-1:before { content: "\f1b5"; }
.flaticon-room-key:before { content: "\f1b6"; }
.flaticon-paper:before { content: "\f1b7"; }
.flaticon-question:before { content: "\f1b8"; }
.flaticon-24-hours:before { content: "\f1b9"; }
.flaticon-credit-card:before { content: "\f1ba"; }
.flaticon-call-center:before { content: "\f1bb"; }
.flaticon-checked:before { content: "\f1bc"; }
.flaticon-chat-2:before { content: "\f1bd"; }
.flaticon-printer-1:before { content: "\f1be"; }
.flaticon-building:before { content: "\f1bf"; }
.flaticon-flash-1:before { content: "\f1c0"; }
.flaticon-transport:before { content: "\f1c1"; }
.flaticon-car:before { content: "\f1c2"; }
.flaticon-login:before { content: "\f1c3"; }
.flaticon-hotel:before { content: "\f1c4"; }
.flaticon-travel:before { content: "\f1c5"; }
.flaticon-heart-1:before { content: "\f1c6"; }
.flaticon-heart:before { content: "\f1c7"; }
.flaticon-planet-earth:before { content: "\f1c8"; }
.flaticon-unlocked:before { content: "\f1c9"; }
.flaticon-locked:before { content: "\f1ca"; }
.flaticon-user-3:before { content: "\f1cb"; }
.flaticon-user-2:before { content: "\f1cc"; }
.flaticon-user-1:before { content: "\f1cd"; }
.flaticon-user:before { content: "\f1ce"; }
.flaticon-users-1:before { content: "\f1cf"; }
.flaticon-users:before { content: "\f1d0"; }
.flaticon-paper-plane:before { content: "\f1d1"; }
.flaticon-calendar-2:before { content: "\f1d2"; }
.flaticon-calendar-1:before { content: "\f1d3"; }
.flaticon-clock-3:before { content: "\f1d4"; }
.flaticon-map:before { content: "\f1d5"; }
.flaticon-like-1:before { content: "\f1d6"; }
.flaticon-info:before { content: "\f1d7"; }
.flaticon-luggage:before { content: "\f1d8"; }
.flaticon-list:before { content: "\f1d9"; }
.flaticon-calendar:before { content: "\f1da"; }
.flaticon-interface-2:before { content: "\f1db"; }
.flaticon-interface-1:before { content: "\f1dc"; }
.flaticon-interface:before { content: "\f1dd"; }
.flaticon-chat-1:before { content: "\f1de"; }
.flaticon-chat:before { content: "\f1df"; }
.flaticon-price-tag-1:before { content: "\f1e0"; }
.flaticon-price-tag:before { content: "\f1e1"; }
.flaticon-customer-service:before { content: "\f1e2"; }
.flaticon-clock-2:before { content: "\f1e3"; }
.flaticon-flash:before { content: "\f1e4"; }
.flaticon-like:before { content: "\f1e5"; }
.flaticon-bus:before { content: "\f1e6"; }
.flaticon-ticket:before { content: "\f1e7"; }
.flaticon-phone-call:before { content: "\f1e8"; }
.flaticon-cancel:before { content: "\f1e9"; }
.flaticon-printer:before { content: "\f1ea"; }
.flaticon-placeholder:before { content: "\f1eb"; }
.flaticon-coin:before { content: "\f1ec"; }
.flaticon-disabled-sign:before { content: "\f1ed"; }
.flaticon-error-404:before { content: "\f1ee"; }
.flaticon-secure-payment:before { content: "\f1ef"; }
.flaticon-arrows:before { content: "\f1f0"; }
.flaticon-credit-cards-payment:before { content: "\f1f1"; }
.flaticon-minibus:before { content: "\f1f2"; }
.flaticon-passage-of-time:before { content: "\f1f3"; }
.flaticon-time-left:before { content: "\f1f4"; }
.flaticon-clock-1:before { content: "\f1f5"; }
.flaticon-clock:before { content: "\f1f6"; }
.flaticon-clock-circular-outline:before { content: "\f1f7"; }
.flaticon-placeholder-filled-point:before { content: "\f1f8"; }
.flaticon-chat-comment-oval-speech-bubble-with-text-lines:before { content: "\f1f9"; }
.flaticon-airplane-flight-in-circle-around-earth:before { content: "\f1fa"; }
.flaticon-kids-couple:before { content: "\f1fb"; }
.flaticon-wheelchair:before { content: "\f1fc"; }
.flaticon-conversation-with-text-lines:before { content: "\f1fd"; }
.flaticon-thumb-up:before { content: "\f1fe"; }